@import '../../theme/global';

.button {
  border-radius: 100px;
  background-color: $green-highlight;
  color: white;
  border: none;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  padding: 10px 16px;
}

.button:hover {
  background-color: lighten($green-highlight, 2);
  color: white;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
