@import '../../theme/global';

$navbar-active-indicator-height: 3px;
$navbar-active-indicator-color: #00b7a5;

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.content {
  display: flex;
  flex-direction: row;
}

.navBarToggle {
  display: none;
}

@media only screen and (max-width: 992px) {
  .container {
    flex-direction: column;
  }

  .navBarToggle {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

nav {
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background: transparent;
  margin-bottom: 0;
  border-bottom: solid 2px #eff3f5;

  a {
    background: none;
    color: #555 !important;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0px 15px;
  }

  .navbarBrand {
    display: flex;
    height: 90px;
    padding: 12px;

    @media (max-width: 991px) {
      padding: 12px;
      padding-right: 0;
      height: 84px;

      .companyLogo {
        width: 60px;
        height: 60px;
        overflow: hidden;
        display: inline-block;
        img {
          max-width: inherit;
          width: 250px;
        }
      }

      .portalLogo {
        display: flex;
        align-items: center;
        img {
          max-width: 100%;
        }
      }
    }

    @media (min-width: 992px) {
      .companyLogo {
        display: flex;
        align-items: center;
        width: 200px;
      }

      .portalLogo {
        display: flex;
        align-items: center;
        width: 150px;
        img {
          max-width: 100%;
        }
      }
    }
  }
  .companyLogo {
    max-width: 250px;

    img {
      max-width: 100%;
      height: auto;
    }

    @media (max-width: 767px) {
    }
    @media (min-width: 992px) {
      max-width: 300px;
    }
    width: 100%;
  }

  .portalLogo {
    width: 200px;
  }
}
