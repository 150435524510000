@import '../../theme/global';

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 992px) {
    width: 80%;
    margin: 0;
  }
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
  }
}

.emailPhoneContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;
}

.genderContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}

.genderInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  input {
    margin-right: 10px;
  }

  p {
    margin-bottom: 0 !important;
    margin-right: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #4c6072;
  }
}

.label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #4c6072;
  margin-bottom: 5px;
}

.foodTherapyPlanContainer {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.foodTherapyTextArea {
  width: 100%;
  height: 200px;
  padding: 10px;
  font-weight: 500;
  color: #666;
  background: #f3f3f3;
  transition: all 0.3s ease;
}

.anaphylaxisContainer {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.anaphylaxisOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;

  input {
    margin-right: 10px;
  }
  p {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #4c6072;
    margin-bottom: 0 !important;
  }
}

.uploadsContainer {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
}

.uploadBoxesContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: stretch;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
  }
}

.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 5px;
  border: dashed 1px #c1ccd5;
  text-align: center;
  padding: 15px;
  min-height: 50px;
  width: 33%;

  p {
    margin-bottom: 0 !important;
  }
  input {
    opacity: 0;
  }

  @media (max-width: 767px) {
    width: auto;
  }

  .file-name {
    font-weight: 600;

    .glyphicon {
      margin-right: 0.5rem;
    }

    &.no-file {
      color: #c1ccd5;
      font-weight: 400;
    }
  }

  label {
    font-size: 15px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0;
  }

  .progress {
    height: 4px;
    margin: 7px;
    border-radius: 100px;

    .progress-bar {
      background-image: linear-gradient(95deg, #374c89, #7b3d7e);
    }
  }
}

.buttonContainer {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-bottom: 0px !important;
    padding-left: 15px;
  }
}
