@import '../../theme/global';

.all {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 100px;
}

.backBar {
  width: 100%;
  height: 50px;
  background-color: #eff3f5;

  div {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }

  p {
    margin-left: 5px;
    margin-bottom: 0 !important;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #4c6072;
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
}
