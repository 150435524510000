@import '../../theme/global';

.all {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.planContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
}

.leftContainer {
    flex: 1;
}

.rightContainer {
    flex: 3;
    padding-left: 15px;

    p {
        white-space: pre-line;
    }
}

.fileContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
}

.fileContentContainer {
    flex: 3;
    padding-left: 15px;
}