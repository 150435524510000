$page-margin-size: 121px;
$deemphasized-text-color: #6e859d;
$emphasized-text-color: #4c6072;
$dark-background-color: darken($emphasized-text-color, 20%);
$border-color: #eff3f5;
$highlight-color: #4ba8ff;
$separator-color: #e5eaef;
$green-highlight: #00b7a5;
$teal: #47c3d4;
$main-color: #3e3f42;
