@import '../../theme/global';

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 992px) {
    margin: 0;
  }
}

.header {
  justify-content: space-between;
  margin: 40px 15px 0px;
}

.searchHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .searchHeader {
    justify-content: flex-end;
    align-items: flex-end;

    div {
      width: 30%;
    }
  }
}

@media (max-width: 768px) {
  .searchHeader {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 20px;
    div {
      width: 100%;
    }
  }
}
