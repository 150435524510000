$navbar-active-indicator-height: 3px;
$navbar-active-indicator-color: #00b7a5;

.container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.leftNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
}

.rightNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  p {
    color: #777;
    margin-left: 5px;
    margin-bottom: 0 !important;
  }
}

.navItem {
  font-weight: 400;

  &:hover {
    color: #777;
    text-decoration: none;
  }
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.activeNavItem {
  background: none;
  color: $navbar-active-indicator-color !important;
  border-bottom: $navbar-active-indicator-height solid $navbar-active-indicator-color;
  &:hover {
    background: none;
    text-decoration: none;
  }
  &:focus {
    background: none;
    text-decoration: none;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .leftNav {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .rightNav {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .user {
    margin-bottom: 10px !important;
  }
  .navItem {
    margin-bottom: 10px;
  }

  .activeNavItem {
    margin-bottom: 10px;
  }
}
