@import '../../theme/global';

.input {
  width: 100%;
  height: 34px;
  border: 1px solid #929292;
  border-radius: 100px;
  box-shadow: none;
  padding: 24px;
  font-weight: 500;
  color: #666;
  background: #f3f3f3;
  transition: all 0.3s ease;
}

.input:hover {
  box-shadow: 0 0 1pt 1pt #c9cdd8;
}

.input:focus {
  outline: none;
}

.label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #4c6072;
  margin-bottom: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.container {
  border: none;
  outline: none;
}

.errorText {
  color: red !important;
  font-size: 14px;
  margin-top: 5px;
  align-self: flex-end;
}
