@import '../../theme/global';

.auth-form-container {
  form {
    margin-top: 122px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 600px;
    min-height: 530px;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 74px;

    @media (max-width: 767px) {
      margin-top: 0px;
      padding: 60px 28px;
    }

    .description {
      margin-bottom: 2rem;
    }

    .company-logo {
      display: block;
      width: 100%;
      height: auto;
      background: $separator-color;
      border-style: none;
    }

    .portal-logo {
      display: block;
      margin: 0 auto;
      margin-bottom: 2rem;
      max-width: 100%;

      @media (max-width: 768px) {
        max-width: 240px;
      }
    }

    .company-name {
      text-align: center;
      color: $emphasized-text-color;
      margin-bottom: 46px;
      font-size: 20px;
    }

    button {
      display: block;
      margin: 0 auto;
      width: 40%;
      padding: 4%;
      margin-top: 30px;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      background-color: #00b7a5;
    }
  }
}

.backContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
