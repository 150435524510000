@import './theme/global';

.spinner-container {
  min-height: 200px;
  display: flex;
  align-items: center;
}

.react-spinner {
  &_bar {
    background-color: black !important;
  }
}

body {
  color: rgb(112, 134, 158);
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

.page-header {
  padding: 0;
  border: 0;
  margin: 40px 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  h1 {
    margin: 0;
  }

  small {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: $deemphasized-text-color;
    margin-top: 15px;
  }
}

h1 {
  color: rgb(76, 96, 114);
}

a,
button {
  transition: all 0.3s ease;
}

.btn {
  &-back {
    margin-left: 5px;
    padding: 0px !important;
  }
  &-link {
    color: $teal;
  }
}

.table {
  thead {
    tr {
      th {
        text-transform: uppercase;
        color: $emphasized-text-color;
        border-bottom: 1px solid $border-color;
      }

      td {
        border-bottom: 1px solid $border-color;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 15px;
        border-bottom: 1px solid $border-color;
      }
    }
  }
}

.ReactTable {
  margin-top: 10px;
}

.rt-td {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.rt-thead {
  min-height: 40px;
  display: flex;
  text-align: center;
}

.rt-th {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#root {
  .nav {
    &.navbar {
      background: #fff;
    }
  }
  form {
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.5px;
      color: $emphasized-text-color;
    }

    $input-underline-color: #594584;

    input.form-control {
      border: 1px solid #929292;
      border-radius: 100px;
      box-shadow: none;
      padding: 24px;
      font-weight: 500;
      color: #666;
      background: #f3f3f3;
      transition: all 0.3s ease;
      // text-align: center;
      // font-size: 16px;
    }

    input:focus {
      color: #333;
      background: #ececec;
      border-color: #666;
    }

    ::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #ccc;
    }

    span.input-group-addon {
      border: none;
      background: none;
      font-size: 13px;
      font-style: italic;
      color: $deemphasized-text-color;
    }

    button {
      border-radius: 100px;
      background-color: $green-highlight;
      color: white;
      border: none;
    }

    button:hover {
      background-color: lighten($green-highlight, 2);
      color: white;
    }
  }
}

// React-spinner is 32x32 px. Contrary to what the docs suggest, you cant make this smaller. This causes problems when
// placing the spinner in a Bootstrap form element addon, since elements need to be ~16px to display nicely inside
// addons. This style override shrinks spinners within addons so they look nice.
.input-group-addon {
  .react-spinner {
    width: 16px;
    height: 16px;
    margin-top: 8px;
    margin-bottom: -8px;
  }
}

body {
  margin: 0;
}

.Navbar {
  background-color: #46acc2;
  display: flex;
  padding: 16px;
  font-family: sans-serif;
  color: white;
}

.Navbar__Link {
  padding-right: 8px;
}

.Navbar__Items {
  display: flex;
}

.Navbar__Items--right {
  margin-left: auto;
}

.Navbar__Link-toggle {
  display: none;
}

@media only screen and (max-width: 768px) {
  .Navbar__Items,
  .Navbar {
    flex-direction: column;
  }
  .Navbar__Items {
    display: none;
  }
  .Navbar__Items--right {
    margin-left: 0;
  }
  .Navbar__ToggleShow {
    display: flex;
  }
  .Navbar__Link-toggle {
    align-self: flex-end;
    display: initial;
    position: absolute;
    cursor: pointer;
  }
}

@media screen and (max-width: 479px) {
  /* start of phone styles */
  .react-tabs {
    display: none;
  }
}

.react-tabs__tab {
  flex: 1;
  text-align: center;
}

.react-tabs__tab--selected {
  color: $emphasized-text-color;
  font-weight: bold;
}

.dropzone {
  margin-bottom: 10px;
  cursor: pointer;

  &:active {
    outline: none;
  }

  &:hover {
    outline: none;
  }
}

.dropzone--isActive {
  padding: 15px;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: $teal !important;
}
